import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = ({location}) => (
    <Layout location={location}>
        <section className="section" style={{height: '500px'}}>
            <div className="container has-text-centered" style={{marginTop: '170px'}}>
                <div className="content">
                    <h1 className="has-text-primary">404</h1>
                    <p>Strona o podanym adresie nie istnieje</p>
                </div>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
